import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  InputAdornment
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import axios from "axios";
import { API_URL } from "Context/GlobalState";
import { GlobalContext } from "Context/GlobalState";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './style.css';

export default function Admin(props) {
  const { updateCollaboration } = useContext(GlobalContext);
  const { dataset,
    invited_users,
    isPrivate,
    flops,
    description,
    allow_experiments
  } = props;
  
  // Competition Configuration States
  const [flopsLimit, setFlopsLimit] = useState(flops / 1e12);
  const [competitionDescription, setCompetitionDescription] = useState(description);
  const [edaPdf, setEdaPdf] = useState(null);
  const [configErrors, setConfigErrors] = useState({
    flops: '',
    description: '',
    edaFile: ''
  });

  // Experiment Control States
  const [allowExperiments, setAllowExperiments] = useState(allow_experiments);
  const [isExperimentLoading, setIsExperimentLoading] = useState(false);

  // Invited Users States
  const [users, setUsers] = useState(invited_users || []);
  const [emailInput, setEmailInput] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  
  // Add new state for flops loading
  const [flopsLoading, setFlopsLoading] = useState(false);

  // Add new state for configuration loading
  const [configLoading, setConfigLoading] = useState(false);

  // Add useEffect to update allowExperiments when prop changes
  useEffect(() => {
    setAllowExperiments(allow_experiments);
  }, [allow_experiments]);

  // Competition Configuration Functions
  const validateInputs = () => {
    const newErrors = {};
    let isValid = true;

    if (!flopsLimit || flopsLimit <= 0) {
      newErrors.flops = 'Valid FLOPS limit is required';
      isValid = false;
    }

    if (!competitionDescription?.trim()) {
      newErrors.description = 'Description is required';
      isValid = false;
    }

    setConfigErrors(newErrors);
    return isValid;
  };

  const updateFlopsConfiguration = async () => {
    if (!validateInputs()) return;
    setFlopsLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");
    const formData = new FormData();
    formData.append('flops', flopsLimit * 1e12);
    try {
      const response = await axios.patch(
        `${API_URL}/competition/${dataset}/`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      if (response.status === 200) {
        updateCollaboration({...response.data});
      }
    } catch (error) {
      console.error("Error updating Flops:", error);
    } finally {
      setFlopsLoading(false);
    }
  };

  const updateConfiguration = async () => {
    if (!validateInputs()) return;
    setConfigLoading(true);

    const token = localStorage.getItem("_key_usr_tkn");
    const formData = new FormData();
    
    formData.append('flops', flopsLimit * 1e12);
    formData.append('description', competitionDescription);
    if (edaPdf) formData.append('eda_pdf', edaPdf);

    try {
      const response = await axios.patch(
        `${API_URL}/competition/${dataset}/`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      if (response.status === 200) {
        updateCollaboration({...response.data});
      }
    } catch (error) {
      console.error("Error updating configuration:", error);
    } finally {
      setConfigLoading(false);
    }
  };

  // Experiment Control Functions
  const handleExperimentToggle = async (event) => {
    setIsExperimentLoading(true);
    const newState = event.target.checked;
    const token = localStorage.getItem("_key_usr_tkn");
    
    try {
      // Choose endpoint based on the switch state
      const endpoint = newState ? 'admin_allow_experiments' : 'admin_pause_experiments';
      
      const response = await axios.get(
        `${API_URL}/competition/${dataset}/${endpoint}/`,
        {
          headers: { Authorization: `Token ${token}` }
        }
      );
      
      if (response.status === 202 || response.status === 200) {
        setAllowExperiments(response.data.allow_experiments);
        updateCollaboration({...response.data});
      }
    } catch (error) {
      console.error("Error toggling experiments:", error);
      setAllowExperiments(newState); // Revert switch state on error
    } finally {
      setIsExperimentLoading(false);
    }
  };

  // Invited Users Functions
  const handleInvite = async () => {
    setInviteLoading(true);
    const newEmails = emailInput.split(',').map(email => email.trim()).filter(Boolean);
    if (newEmails.length === 0) return;

    const token = localStorage.getItem("_key_usr_tkn");

    try {
      const response = await axios.patch(
        `${API_URL}/competition/${dataset}/admin_invite/`,
        { emails: newEmails.toString() },
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        setUsers(response.data['invited_users']);
        setEmailInput('');
        updateCollaboration({...response.data});
      }
    } catch (error) {
      console.error("Error inviting users:", error);
    } finally {
      setInviteLoading(false);
    }
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");

    try {
      const response = await axios.patch(
        `${API_URL}/competition/${dataset}/admin_uninvite/`,
        { emails: selectedEmail },
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        setUsers(prevUsers => prevUsers.filter(user => user.email !== selectedEmail));
        updateCollaboration({...response.data});
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setDeleteLoading(false);
      setOpenDialog(false);
    }
  };


  console.log('allowExperiments', props, allowExperiments, allow_experiments);
  return (
    <Box p={3}>
      {/* <Typography variant="h4" style={{ marginBottom: 24 }} gutterBottom>
        Collaboration Management
      </Typography> */}

      {/* Competition Configuration Section */}
      {isPrivate && (
        <Box 
          display="flex" 
          justifyContent="space-between" 
          alignItems="stretch" 
          gap={4} 
          mt={2}
          mb={3}
        >
          {/* Left Column - Per Team Budget */}
          <Paper className="admin-card config-card">
            <Typography className="card-header" variant="h6" gutterBottom>
              Computation Budget per Team
            </Typography>
            <Typography variant="body2" color="textPrimary" style={{ marginBottom: 0 }}>
              Enter compute budget in TFLOP
            </Typography>
            <Box  mt={1} mb={1} >
            <TextField
              type="number"
              label=""
              variant="outlined"
              margin="dense"
              placeholder="e.g 1000"
              style={{ width: '100%', fontSize: 20, fontWeight: 'bold' }}
              value={flopsLimit}
              onChange={(e) => {
                setFlopsLimit(e.target.value);
                setConfigErrors(prev => ({ ...prev, flops: '' }));
              }}
              error={!!configErrors.flops}
                InputProps={{
                style: {
                  fontSize: 20,
                  fontWeight: 'bold'
                },
                endAdornment: (
                  <>
                    <InputAdornment className="flops-label" position="end">TFLOP</InputAdornment>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={updateFlopsConfiguration}
                      disabled={!flopsLimit || configErrors.flops || flopsLoading}
                      style={{
                        borderTopRightRadius: 3,
                        borderBottomRightRadius: 3,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        marginLeft: 8,
                        marginTop: 0,
                        marginBottom: 0,
                        marginRight: -13,
                        padding: '4px 0px',
                        minWidth: '100px',
                        fontSize: 20,
                        fontWeight: 'bold'
                      }}
                    >
                      {flopsLoading ? 'Applying...' : 'Apply'}
                    </Button>
                  </>
                ),
              }}
              />
            </Box>
            <Box mt={4}>
              <Typography variant="body2" color="textPrimary">
                Total: $ <span style={{ fontWeight: 'bold' }}>{(flopsLimit * 0.0549).toFixed(2)}</span> per team
              </Typography>
            </Box>
          </Paper>

          {/* Middle Column - Total Budget */}
          <Paper className="admin-card config-card">
            <Typography className="card-header" variant="h6" gutterBottom>
              Total Compute Budget
            </Typography>
            <Typography variant="body2" color="textPrimary" style={{ marginBottom: 12 }}>
              Max compute budget for this collaboration
            </Typography>
            <Typography className="budget-display" variant="h4" style={{ marginBottom: 12 }}>
              {flopsLimit * (users.length || 1)} TFLOP | $ {(flopsLimit * (users.length || 1) * 0.0549).toFixed(2)}
            </Typography>
            <Typography style={{ marginTop: 32 }} variant="body2" color="textPrimary">
            Compute Budget per Team x Invited Users
            </Typography>
          </Paper>

          {/* Right Column - Switch */}
          <Paper className="admin-card config-card">
            <Typography className="card-header" variant="h6" gutterBottom>
              Experiments Control
            </Typography>
            <Typography variant="body2" color="textPrimary" style={{ marginBottom: 12 }}>
              Control training & inference of user's models
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography className="budget-display" variant="body2" color="textPrimary">
                {allowExperiments ? 'Allow training & inference' : 'Restrict training & inference'}
              </Typography>
              <Switch
                checked={allowExperiments}
                onChange={handleExperimentToggle}
                disabled={isExperimentLoading}
                color="primary"
                className="experiment-switch"
              />
            </Box>
            <Typography 
              variant="body2" 
              className={allowExperiments ? "switch-status-on" : "switch-status-off"}
              style={{ marginTop: 32 }}
            >
              {allowExperiments 
                ? "Teams can run experiments" 
                : "Teams cannot run experiments"}
            </Typography>
          </Paper>
        </Box>
      )}

      {/* Invited Users Section */}
      {isPrivate && (
        <>
          <Paper className="admin-card" style={{ marginBottom: 24, padding: 24 }}>
            <Typography className="card-header" variant="h6" style={{ marginBottom: 24 }} gutterBottom>
              Invited Users
            </Typography>
            
            <Box display="flex" alignItems="center" gap={2}>
              <TextField
                label="Emails (comma-separated)"
                variant="outlined"
                style={{ flexGrow: 1 }}
                value={emailInput}
                margin="dense"
                onChange={(e) => setEmailInput(e.target.value)}
                placeholder="email1@example.com, email2@example.com"
                InputProps={{
                  endAdornment: (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleInvite}
                      disabled={!emailInput.trim() || inviteLoading}
                      style={{
                        borderRadius: 3,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        marginTop: -1,
                        marginBottom: -1,
                        marginRight: -14,
                        padding: '7px 18px'
                      }}
                    >
                      {inviteLoading ? 'Inviting...' : 'Invite'}
                    </Button>
                  ),
                }}
              />
            </Box>

            {users.length > 0 && (
              <Box className="users-table-container">
                <Table className="users-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Access</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user.email}>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          {user.first_name || user.last_name 
                            ? `${user.first_name || ''} ${user.last_name || ''}`
                            : ' - '}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Delete">
                            <IconButton
                              size="small"
                              onClick={() => {
                                setSelectedEmail(user.email);
                                setOpenDialog(true);
                              }}
                            >
                              <DeleteIcon color="textSecondary" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
              <DialogTitle>Confirm Deletion</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this user?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)} color="primary">
                  Cancel
                </Button>
                <Button 
                  onClick={handleDelete} 
                  color="primary" 
                  disabled={deleteLoading}
                >
                  {deleteLoading ? 'Deleting...' : 'Confirm'}
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>

          {/* New Card for Description and EDA */}
          <Paper className="admin-card" style={{ padding: 24 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography className="card-header" variant="h6" style={{ marginBottom: 20 }} gutterBottom>
                  Collaboration Description
                </Typography>
                <CKEditor
                  editor={ClassicEditor}
                  data={competitionDescription}
                  onChange={(event, editor) => {
                    setCompetitionDescription(editor.getData());
                    setConfigErrors(prev => ({ ...prev, description: '' }));
                  }}
                />
                {configErrors.description && (
                  <Typography color="error" variant="caption">
                    {configErrors.description}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" color="textPrimary" style={{ marginBottom: 16 }} gutterBottom>
                  Please upload a JSON file for EDA.
                </Typography>
                <input
                  accept=".json,.pdf"
                  style={{ display: 'none' }}
                  id="eda-file-upload"
                  type="file"
                  onChange={(e) => setEdaPdf(e.target.files[0])}
                />
                <label htmlFor="eda-file-upload">
                  <Button className="upload-button" variant="outlined" component="span" color="primary">
                    Upload EDA
                  </Button>
                </label>
                {edaPdf && (
                  <Typography variant="caption" display="block">
                    Selected file: {edaPdf.name}
                  </Typography>
                )}
              </Grid>

              <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} item xs={12}>
                <Button 
                  className="apply-button"
                  variant="contained" 
                  color="primary" 
                  onClick={updateConfiguration}
                  disabled={configLoading}
                  fullWidth
                >
                  {configLoading ? 'Updating...' : 'Update Configuration'}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </Box>
  );
} 