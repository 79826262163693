import {
  Box,
  CircularProgress,
  Grid
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import { JupyterNotebookViewer } from "react-jupyter-notebook-viewer";
import JupyterViewer from "react-jupyter-notebook";
import './style.css';
import axios from "axios";

const NoteBook = ({ json }) => {
  const [eda, setEda] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getJSon() {
      try {
        setLoading(true);
        const response = await axios({
          url: json,
          method: 'GET',
          // responseType: 'blob', 
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
  
        console.log("NOTEBOOK-JSON-DATA", response.data);
        setEda(response.data)
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    if (json) {
      getJSon()
    }

  }, [json])

  console.log("###JSON", json)


  return (
    <>
      {
        loading && <Grid style={{ justifyContent: 'center', alignItems: 'center', padding: 20, minHeight: 500 }} container>
          <Grid item> <CircularProgress size={30} /> </Grid>
        </Grid>
      }
      {(eda && !loading) &&
        <Box className="notebook-viewer">
          {/* <h1>notebook</h1> */}
          <JupyterViewer
            rawIpynb={eda}
            mediaAlign="center"
          />
        </Box>
      }

        { !json &&
        <Grid Grid style={{ justifyContent: 'center', alignItems: 'center', padding: 20, minHeight: 500 }} container>
          <Grid item> No EDA found. </Grid>
          </Grid>
        }
      
    </>
  );
};

export default NoteBook;
